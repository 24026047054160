<template>
  <div>
    <BaseCheckbox
      label="Has physical location"
      v-model="hasPhysicalLocation"
    />
    <div class="field">
      <div
        class="control has-icons-left"
      >
        <vue-google-autocomplete
          id="googleAddress"
          ref="address"
          :types="types"
          :placeholder="placeholder"
          classname="input"
          v-on:placechanged="getAddressData"
          country="us"
        >
        </vue-google-autocomplete>
        <span
          class="icon is-small is-left">
          <i class="fas fa-search"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCheckbox from "@/components/form/BaseCheckbox.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import api from "@/service/api.js";
import dmiApi from "@/service/dmiApi.js";
import parseAddress from "parse-address";
import distance from "@turf/distance";
import * as turf from "@turf/helpers";

export default {
  props: {
    value: {
      type: String
    },
    business: {
      type: String,
      required: true
    }
  },
  components: { 
    BaseCheckbox,
    VueGoogleAutocomplete
  },
  data() {
    return {
      hasPhysicalLocation: true
    }
  },
  computed: {
    types() {
      return (this.hasPhysicalLocation) ? "address" : "(cities)";
    },
    placeholder() {
      return (this.hasPhysicalLocation) ? "Enter and select address (not suite number)" : "Enter and select city";
    },
    currentMarket() {
      return this.$store.getters.currentMarket;
    }
  },
  watch: {
    value: function() {
      this.$refs.address.update(this.value)
      //this.$refs.address.focus()
    }
  },
  methods: {
    getMarketForBusinessAddress: async function(businessName, address) {
      // find businesses with matching names
      const response = await dmiApi.getBusinesses(businessName)
      if (!response.data || !response.data.data || !response.data.data.length) {
        return null
      }

      const businesses = response.data.data
      const parsedAddr = parseAddress.parseLocation(address)
      const business = await findAsync(businesses, async (b) => {
        // does the business address match?
        if (b.attributes && b.attributes["full-address"]) {
          const parsedBusinessAddr = parseAddress.parseLocation(b.attributes["full-address"])
          if (matchParsedAddresses(parsedAddr, parsedBusinessAddr)) {
            return true
          }
        }

        // search Extra Location & Cross Reference addresses
        let response = await dmiApi.getBusinessExtraLocations(b.id)
        if (response.data && response.data.data && response.data.data.length) {
          const extraLocations = response.data.data
          const extraLocation = extraLocations.find(el => {
            // does the Extra Location address match?
            const parsedLocationAddr = parseAddress.parseLocation([el.attributes["location"], el.attributes["city-state"]].join(", "))
            return matchParsedAddresses(parsedAddr, parsedLocationAddr)
          })
          if (extraLocation) {
            return true
          }
        }
        response = await dmiApi.getBusinessCrossReferences(b.id)
        if (response.data && response.data.data && response.data.data.length) {
          const crossReferences = response.data.data
          const crossReference = crossReferences.find(cr => {
            // Does the Cross Reference address match?
            const parsedCrossRefAddr = parseAddress.parseLocation(cr.attributes["location"])
            return matchParsedAddresses(parsedAddr, parsedCrossRefAddr)
          })
          if (crossReference) {
            return true
          }
        }

        return false
      })
      if (business) {
        // get the business's market
        const market = await dmiApi.getBusinessDestination(business.id)
        if (market.data && market.data.data) {
          return market.data.data
        }
      }
      return null
    },
    inDmiMarket: async function(p, dmiMarketId) {
      const response = await dmiApi.getDestination(dmiMarketId)
      if (!response || !response.data || !response.data.data) {
        return false
      }

      const destination = response.data.data
      if (!p || !destination.attributes || !destination.attributes.longitude || !destination.attributes.latitude || !destination.attributes.radius) {
        return false
      }

      const marketCenter = turf.point([destination.attributes.longitude, destination.attributes.latitude])
      const dist = distance(marketCenter, p, {units: "miles"})
      if (dist <= destination.attributes.radius) {
        return true
      }
      return false
    },
    getAddressData: async function(addressData, placeResultData) {
      const is_physical_location = this.hasPhysicalLocation;
      const { formatted_address } = placeResultData;
      const { 
        latitude: lat, 
        longitude: lng,  
        locality: locality, 
        administrative_area_level_1: administrative_area_level_1, 
        postal_code: postal_code, 
        country: country
      } = addressData;

      // try to find a matching businesses & addresses
      let market
      try {
        const dmiMarket = await this.getMarketForBusinessAddress(this.business, formatted_address)
        const resp = await api.getDmiMarketByDmiId(dmiMarket.id)
        if (resp.data) {
          market = resp.data
        }
      } catch(exception) {
        console.log(exception);
      }

      const location = {
        lat,
        lng,
        formatted_address,
        locality,
        administrative_area_level_1,
        postal_code,
        country,
        is_physical_location
      };
      if (market) {
        location.dmi_market_id = market.id
      } else if (this.currentMarket) {
        const point = turf.point([lng, lat])
        if (this.inDmiMarket(point, this.currentMarket.dmi_id)) {
          location.dmi_market_id = this.currentMarket.id
        }
      }
      this.$refs.address.$refs.autocomplete.value = '';
      this.$parent.addLocation(location)
      this.$emit('input', formatted_address);
    },
  }
};

function matchParsedAddresses(addr1, addr2) {
  if (!addr1.state || !addr2.state || addr1.state != addr2.state
      || !addr1.city || !addr2.city || addr1.city != addr2.city) {
    return false
  }
  if ((addr1.type || addr2.type) && (!addr1.type || !addr2.type || addr1.type != addr2.type)) {
    return false
  }
  if ((addr1.street || addr2.street) && (!addr1.street || !addr2.street || addr1.street != addr2.street)) {
    return false
  }
  if ((addr1.prefix || addr2.prefix) && (!addr1.prefix || !addr2.prefix || addr1.prefix != addr2.prefix)) {
    return false
  }
  if ((addr1.number || addr2.number) && (!addr1.number || !addr2.number || addr1.number != addr2.number)) {
    return false
  }
  // we don't check zipcode here as we expect one address to have a zipcode and the other to not

  return true
}

// See:
// https://stackoverflow.com/questions/55601062/using-an-async-function-in-array-find
// https://advancedweb.hu/how-to-use-async-functions-with-array-map-in-javascript/
// here it's fine to let the promises run in parallel
async function findAsync(arr, asyncCallback) {
  const promises = arr.map(asyncCallback)
  const results = await Promise.all(promises)
  const index = results.findIndex(result => result)
  return arr[index]
}
</script>


