import axios from "axios"
import qs from "qs"

const apiClient = axios.create({
  baseURL: "https://discoverymap.com/api/v1/",
  withCredentials: false,
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  }
})

export default {
  getBusinesses(search) {
    const query = {
      filter: {
        business_name: search,
      },
      page: {
        limit: 10,
        offset: 0,
      },
    };
    const queryString = qs.stringify(query, { indices: false });
    return apiClient.get(`businesses?${queryString}`)
  },

  getBusinessPhoneNumbers(businessId) {
    return apiClient.get(`businesses/${businessId}/phone-numbers`)
  },

  getBusinessWebUrls(businessId) {
    return apiClient.get(`businesses/${businessId}/weburls`)
  },

  getBusinessExtraLocations(businessId) {
    return apiClient.get(`businesses/${businessId}/extra-locations`)
  },

  getBusinessCrossReferences(businessId) {
    return apiClient.get(`businesses/${businessId}/cross-references`)
  },

  getBusinessDestination(businessId) {
    return new Promise((resolve, reject) => {
      // get the business's edition
      this.getBusinessEdition(businessId)
        .then(resp => {
          // get the edition's edition set
          this.getEditionEditionSet(resp.data.data.id)
            .then(resp => {
              // get the edition set's destination/market
              this.getEditionSetDestination(resp.data.data.id)
                .then(resp => {
                  resolve(resp)
                })
                .catch(err => {
                  reject(err)
                })
            })
            .catch(err => {
              reject(err)
            })
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  getBusinessEdition(businessId) {
    return apiClient.get(`businesses/${businessId}/edition`)
  },

  getEditionEditionSet(editionId) {
    return apiClient.get(`editions/${editionId}/edition-set`)
  },

  getEditionSetDestination(editionSetId) {
    return apiClient.get(`edition-sets/${editionSetId}/destination`)
  },

  getDestinations() {
    return apiClient.get("destinations")
  },

  getDestination(destinationId) {
    return apiClient.get(`destinations/${destinationId}`)
  }
}
