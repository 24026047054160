<template>
  <div class="field">
    <label 
      class="checkbox is-block"
    >
      <input
        type="checkbox"
        :checked="isChecked"
        @change="$emit('input', $event.target.checked)"
      />
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
  },
  computed: {
    isChecked() {
      return this.value ? "checked" : "" 
    }
  }
};
</script>


